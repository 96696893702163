import React from "react"
import { graphql, Link } from "gatsby"
import { Box, Grid, Container, Themed } from "theme-ui"

const NotFoundPage = ({ data }) => {

  const dataFragments = data.dataJson

  return (
    <Grid
      as={`div`}
      sx={{
        height: `100vh`,
        margin: `0`,
        placeItems: `center center`
      }}
    >
      <Box as={`main`}>
        <Container>
          <Themed.h1>{dataFragments.fragment_1}</Themed.h1>
          <Box
            sx={{
              variant: 'styles',
            }}
            dangerouslySetInnerHTML={{ __html: dataFragments.fragment_2}}
          />
          <Themed.p><Themed.a as={Link} to="/" aria-label="home">&#x02190; {dataFragments.fragment_3}</Themed.a></Themed.p>
        </Container>
      </Box>
    </Grid>
  )
  
}

export default NotFoundPage

export const query = graphql`
  query data404 {
    dataJson(slug: {eq: "page-404"}) {
      fragment_1
      fragment_2
      fragment_3
    }
  }
`